<template>
	<div>
		<CRow>
			<CCol class="text-right mb-3">
				<CButton
					class="transparent"
					color="secondary"
					@click="onAdd"
				>
					<CIcon class="icon-left" name="cil-plus" />
					Create promotion banner set
				</CButton>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<form class="search-form" @submit.prevent="handleSearch">
					<CInput
						v-model.trim="queryParams.q"
						type="text"
						placeholder="Search by promotion banner set name or set ID"
					>
						<template #prepend-content>
							<button
								type="submit"
								class="btn-search"
							>
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="promotion banner set(s) found"
					reset-filter-btn-text="Reset search"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="FIELDS"
			:items="promotionBannerSets"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			striped
			clickable-rows
			:link-to="ROUTE_NAME.PROMOTION_BANNER_SET_EDIT"
			class="table-custom table-custom-link"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "promotion banner set" }) }}
					</p>
				</div>
			</template>
			<template #isActive="{item}">
				<CBadge class="badge-status" :color="BANNER_STATUSES[item].value">
					{{ BANNER_STATUSES[item].name }}
				</CBadge>
			</template>
			<template #deleteId="{item}">
				<CRow>
					<CCol class="text-right">
						<CButton
							class="transparent p-0"
							@click.prevent="openModalDelete(item)"
						>
							<CIcon
								class="icon-trash"
								name="cil-trash"
							/>
						</CButton>
					</CCol>
				</CRow>
			</template>
		</BaseTable>

		<!-- Modal section -->
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePromotionBannerSet.bind(null, selectedKeyId)"
			title="Remove this promotion banner set?"
			description="Removing this promotion banner set will also remove it and all its values from all products."
			@onSuccess="handleDeleteSuccess"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, randomSearchString } from '../assets/js/helpers';

import BaseModalConfirmDelete from '../components/BaseModalConfirmDelete.vue';

import { ROUTE_NAME } from '../enums/route';
import { BANNER_STATUSES } from '../enums/banners';

const FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Promotion banner sets', class: 'col-name' },
	{ key: 'isActive', label: 'Status', class: 'col-status' },
	{ key: 'deleteId', label: '', class: 'col-id' },
];

export default {
	name: 'PromotionBannerSets',
	components: {
		BaseModalConfirmDelete,
	},
	data() {
		return {
			ROUTE_NAME,
			BANNER_STATUSES,
			FIELDS,
			selectedKeyId: null,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('promotionBannerSets', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			promotionBannerSets: 'promotionBannerSets/promotionBannerSetList',
		}),
	},
	created() {
		this.getPromotionBannerSets(this.queryParams);
	},
	methods: {
		...mapActions({
			getPromotionBannerSets: 'promotionBannerSets/getPromotionBannerSets',
			createPromotionBannerSet: 'promotionBannerSets/createPromotionBannerSet',
			deletePromotionBannerSet: 'promotionBannerSets/deletePromotionBannerSet',
		}),
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		onAdd() {
			this.$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_SET_CREATE });
		},
		async handleDeleteSuccess() {
			this.selectedKeyId = null;
			this.queryParams.q = '';
			this.queryParams.page = null;
			await this.getPromotionBannerSets(this.queryParams);
		},
		openModalDelete(promotionBannerKeyId) {
			this.selectedKeyId = promotionBannerKeyId;
			this.$refs['modal-remove'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		min-width: rem(192);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-next {
		opacity: 0.5;
		width: rem(12);
		height: rem(12);
	}

	::v-deep .col-name {
		z-index: 1;
	}
</style>
