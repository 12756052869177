var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{staticClass:"text-right mb-3"},[_c('CButton',{staticClass:"transparent",attrs:{"color":"secondary"},on:{"click":_vm.onAdd}},[_c('CIcon',{staticClass:"icon-left",attrs:{"name":"cil-plus"}}),_vm._v(" Create promotion banner set ")],1)],1)],1),_c('CRow',[_c('CCol',[_c('form',{staticClass:"search-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSearch($event)}}},[_c('CInput',{attrs:{"type":"text","placeholder":"Search by promotion banner set name or set ID"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('button',{staticClass:"btn-search",attrs:{"type":"submit"}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true}]),model:{value:(_vm.queryParams.q),callback:function ($$v) {_vm.$set(_vm.queryParams, "q", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryParams.q"}})],1)])],1),_c('CRow',{staticClass:"mb-4"},[_c('CCol',[_c('BaseSearchFormFooter',{attrs:{"count":_vm.list.meta.total,"append-text":"promotion banner set(s) found","reset-filter-btn-text":"Reset search"},on:{"onReset":_vm.resetFilter}})],1)],1),_c('BaseTable',{staticClass:"table-custom table-custom-link",attrs:{"is-loading":_vm.list.isLoading,"fields":_vm.FIELDS,"items":_vm.promotionBannerSets,"pagination":{
			pages: _vm.list.meta.lastPage,
			activePage: _vm.list.meta.currentPage
		},"striped":"","clickable-rows":"","link-to":_vm.ROUTE_NAME.PROMOTION_BANNER_SET_EDIT},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("global.searchNotFound", { field: "promotion banner set" }))+" ")])])]},proxy:true},{key:"isActive",fn:function(ref){
		var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":_vm.BANNER_STATUSES[item].value}},[_vm._v(" "+_vm._s(_vm.BANNER_STATUSES[item].name)+" ")])]}},{key:"deleteId",fn:function(ref){
		var item = ref.item;
return [_c('CRow',[_c('CCol',{staticClass:"text-right"},[_c('CButton',{staticClass:"transparent p-0",on:{"click":function($event){$event.preventDefault();return _vm.openModalDelete(item)}}},[_c('CIcon',{staticClass:"icon-trash",attrs:{"name":"cil-trash"}})],1)],1)],1)]}}])}),_c('BaseModalConfirmDelete',{ref:"modal-remove",attrs:{"handle-remove":_vm.deletePromotionBannerSet.bind(null, _vm.selectedKeyId),"title":"Remove this promotion banner set?","description":"Removing this promotion banner set will also remove it and all its values from all products."},on:{"onSuccess":_vm.handleDeleteSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }